@import './anand.const.scss';
// @import '@fontsource/roboto/400.css';
// @import '@fontsource/roboto/700.css';

@media (min-width: $xlg-min-width) {
	.brand-box,
	.rhymes {
		margin-top: -20em;
	}
}

@media (max-width: $lg-max-width) {
	.brand-box,
	.rhymes {
		margin-top: -18em;
	}
}

@media (max-width: $md-max-width) {
	.brand-box,
	.rhymes {
		margin-top: -15em;
	}
	.next-line {
		width: 25vw;
		height: 5vw;
	}
}

@media (max-width: $sm-max-width) {
	.brand-box,
	.rhymes {
		margin-top: -17em;
	}
	.next-line {
		width: 30vw;
		height: 6vw;
	}
}

.ql-container {
	font-family: inherit;

	&.ql-snow,
	&.ql-bubble {
		margin-top: 0;
		margin-bottom: 1em;
	}

	&.ql-bubble {
		box-shadow: 0px 0px 10px 1px red;
		display: block;
	}
}

.ql-container {
	font-size: inherit;
}

.ql-editor {
	text-align: inherit;
	line-height: inherit;
}

.ql-toolbar.ql-snow {
	font-size: 28px;
	text-align: left;
	margin-top: 0.5em;
}

.ql-bubble .ql-toolbar {
	font-size: 28px;
	padding: 5px;
}

.ql-bubble .ql-tooltip {
	z-index: 1;
}

.inline-edit {
	.ql-toolbar.ql-snow {
		position: fixed;
		top: 50px;
		left: 10%;
		right: 10%;
		background-color: white;
		z-index: 1;
		box-shadow: 2px 2px 10px grey;
	}
}

.ql-align-right {
	text-align: right;
}

.ql-align-left {
	text-align: left;
}

.ql-align-center {
	text-align: center;
}

.ql-align-justify {
	text-align: justify;
}

.ql-size-small {
	font-size: 0.75em;
}

.ql-size-large {
	font-size: 1.5em;
}

.ql-size-huge {
	font-size: 2.5em;
}

/* Set dropdown font-families */
.ql-snow,
.ql-bubble {
	.ql-picker.ql-font .ql-picker-label::before,
	.ql-picker.ql-font .ql-picker-item::before {
		content: 'Default';
	}
}

.ql-font span[data-value='HindiUnicode']::before {
	content: 'Uni-Hindi' !important;
}
.ql-font span[data-value='Helvetica']::before {
	content: 'Helvetica' !important;
}
.ql-font span[data-value='Zapfding']::before {
	content: 'Zapfding' !important;
}
.ql-font span[data-value='Initial']::before {
	content: 'Initial' !important;
}

/* Set content font-families */
.ql-font-Helvetica {
	font-family: 'helvetica';
}
.ql-font-HindiUnicode {
	font-family: 'hindiunicode';
}
.ql-font-Zapfding {
	font-family: 'zapfding';
}

/* We do not set Courier since it is the default font */

.ql-snow.ql-toolbar,
.ql-bubble .ql-toolbar {
	.ql-theheading {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: 'H1';
		}
	}

	.ql-thespiritualmaster {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: 'H2';
		}
	}

	.ql-holyteachings {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: 'H3';
		}
	}

	.ql-divblock {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: 'Px';
		}
	}

	.ql-audio {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		position: relative;
		display: inline-block;
		font-family: 'Glyphicons Halflings';
		font-style: normal;
		font-weight: 400;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&:before {
			content: '\e072';
		}
	}

	.ql-rhymehiblot[value='hi'] {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;
		margin: 0 3px;

		&:after {
			content: '~H~';
		}
	}

	.ql-rhymehiblot[value='en'] {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;
		margin: 0 3px;

		&:after {
			content: '~E~';
		}
	}

	.ql-rhymeauthor {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;
		margin: 0 3px;

		&:after {
			content: '~A';
		}
	}

	.ql-rhymeline {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;
		margin: 0 3px;

		&:after {
			content: '~L';
		}
	}

	.ql-poorna {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: '।';
		}
	}

	.ql-poornax2 {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: '।।';
		}
	}

	.ql-id {
		font-size: 0.5em;
		font-weight: bold;
		padding: 0 !important;

		&:after {
			content: 'ID';
		}
	}

	.ql-save,
	.ql-cancel {
		display: none;
	}

	.ql-save:after {
		content: 'Save';
	}

	.ql-cancel:after {
		content: 'Cancel';
	}

	.ql-formats {
		color: #ccc;

		&:last-child {
			float: right;
			margin-top: 0.2em;
		}
	}
}

.ql-container,
.ck.ck-editor {
	.content-inline-rhymes {
		font-weight: normal;
		font-size: 1em !important;
		margin: 0;
		text-align: left;

		rhyme-line,
		.rhyme-line {
			display: block;
			text-align: center;
			// font-weight: bold;
			line-height: inherit;
		}

		rhyme-type {
			display: block;
			text-align: center;
		}

		rhyme-author,
		.rhyme-author {
			margin: 0 !important;
		}
	}

	hide {
		visibility: visible;
		color: transparent;
	}
}

.audio-ctrl {
	width: 100%;
	display: block;
	margin-bottom: 0.5em;
}

divblock {
	display: block;

	img {
		width: 100%;
	}
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	pointer-events: none;
}
