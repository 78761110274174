@keyframes glow {
	0% {
		border-color: #393;
		box-shadow: 0 0 5px rgba(0, 255, 0, 0.2), inset 0 0 5px rgba(0, 255, 0, 0.1), 0 2px 0 #000;
	}
	100% {
		border-color: #6f6;
		box-shadow: 0 0 20px rgba(0, 255, 0, 0.6), inset 0 0 10px rgba(0, 255, 0, 0.4), 0 2px 0 #000;
	}
}

@keyframes glowing {
	0% {
		box-shadow: 0 0 -10px #c4a300;
	}
	40% {
		box-shadow: 0 0 20px #c4a300;
	}
	60% {
		box-shadow: 0 0 20px #c4a300;
	}
	100% {
		box-shadow: 0 0 -10px #c4a300;
	}
}
