$color_bar: #29d9c2;
$color_high: #ff3b77;
$color_background: #2f2933;

.audioindicator {
	width: 100px;
	height: 20px;
	background: $color_bar;
	position: relative;
	transform: translate(-110px, 50px);
	box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 $color_bar, 0 -90px 0 $color_bar, 0 -120px 0 $color_bar;
	animation-name: bar1;
	animation-duration: 0.3s;
	animation-direction: alternate;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	transform: scale(0.1);

	&:before {
		content: '';
		position: absolute;
		transform: translate(110px, 0);
		@extend .audioindicator;
		box-shadow: 0 -30px 0 $color_bar;
		animation-name: bar2;
	}
	&:after {
		content: '';
		position: absolute;
		transform: translate(220px, 0);
		@extend .audioindicator;
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 $color_bar, 0 -90px 0 $color_bar;
		animation-name: bar3;
	}
}

@keyframes bar1 {
	0%,
	19.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
	}
	20%,
	39.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%);
	}
	40%,
	59.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
	}
	60%,
	79.9% {
		box-shadow: 0 -30px 0 $color_bar;
	}
	80%,
	100% {
		box-shadow: none;
	}
}

@keyframes bar2 {
	0%,
	19.9% {
		box-shadow: none;
	}
	20%,
	39.9% {
		box-shadow: 0 -30px 0 $color_bar;
	}
	40%,
	59.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
	}
	60%,
	79.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
	}
	80%,
	100% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%);
	}
}

@keyframes bar3 {
	0%,
	19.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
	}
	20%,
	39.9% {
		box-shadow: 0 -30px 0 $color_bar;
	}
	40%,
	59.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%);
	}
	60%,
	79.9% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%);
	}
	80%,
	100% {
		box-shadow: 0 -30px 0 $color_bar, 0 -60px 0 mix($color_high, $color_bar, 50%),
			0 -90px 0 mix($color_high, $color_bar, 75%), 0 -120px 0 mix($color_high, $color_bar, 100%);
	}
}
