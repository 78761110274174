$xxs-max-width: 420px;
$xs-max-width: 767px;
$xs-width: 100%;
$sm-max-width: 991px;
$sm-width: 95%;
$md-max-width: 1279px;
$md-width: 90%;
$lg-max-width: 1679px;
$lg-width: 90%;
$xlg-min-width: 1680px;
$xlg-width: 90%;

$body-color: #555555;
$body-bg-color: #f5f5f5;

$theme-bg-color: '#038b00'; //#32b78d; //#34C378; //#E377AE;//#DA2991; //#BD3B0B; //#D44204; #26a69a; //
$theme-fg-color: white;
$theme-dfg-color: darken($theme-fg-color, 10%);

$base-img-url: '';

@font-face {
	font-family: 'hindiunicode';
	src: url('../fonts/surekhnormal-new.woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'hindiunicode';
	src: url('../fonts/surekhbold-new.woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'zapfding';
	src: url('../fonts/zapfding.woff2');
	font-weight: 400;
	font-style: normal;
}

@mixin decorate($img, $width, $height) {
	display: inline-block;
	background-image: url($img);
	width: $width;
	height: $height;
	background-size: contain;
	margin: 0 0.7em;
	vertical-align: middle;
}

@mixin endline($img, $width, $height) {
	display: block;
	background-image: url($img);
	width: $width;
	height: $height;
	background-size: cover;
	margin: 0 auto;
}

.no-padding-left {
	padding-left: 0;
}

.nav > li > a:hover,
.nav > li > a:focus {
	outline: none;
}

.locale-hi {
	font-family: 'hindiunicode', helvetica !important;
	letter-spacing: 0 !important;
	word-spacing: 0.15em !important;
}

.locale-en {
	font-family: 'Roboto';
	letter-spacing: 0.05em;
	word-spacing: 0;
}

@mixin locale-hi {
	font-family: 'hindiunicode', helvetica;
	letter-spacing: 0;
	word-spacing: 0.15em;
}

@mixin locale-en {
	font-family: 'Roboto';
	letter-spacing: 0.05em;
	word-spacing: 0;
}

@keyframes sphamadjust-hue {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@mixin sphamadjust-hue {
	animation-name: sphamadjust-hue;
	animation-duration: 40000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes ring {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(30deg);
	}
	3% {
		transform: rotate(-28deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}
	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}

.no-padding {
	padding: 0;
}

.locale-hi {
	label.invalid-field-msg {
		font-family: helvetica;
		font-size: 0.8em;
	}
}
