@keyframes MIFadeInOut {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	41% {
		opacity: 0.95;
	}
	42% {
		opacity: 0.9;
	}
	43% {
		opacity: 0.85;
	}
	44% {
		opacity: 0.8;
	}
	45% {
		opacity: 0.75;
	}
	46% {
		opacity: 0.7;
	}
	47% {
		opacity: 0.65;
	}
	48% {
		opacity: 0.6;
	}
	49% {
		opacity: 0.55;
	}
	50% {
		opacity: 0.5;
	}
	51% {
		opacity: 0.45;
	}
	52% {
		opacity: 0.4;
	}
	53% {
		opacity: 0.35;
	}
	54% {
		opacity: 0.3;
	}
	55% {
		opacity: 0.25;
	}
	56% {
		opacity: 0.2;
	}
	57% {
		opacity: 0.15;
	}
	58% {
		opacity: 0.1;
	}
	59% {
		opacity: 0.05;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
