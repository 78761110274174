.autoplay-progress {
	position: absolute;
	right: 8px;
	/* bottom: 4px; */
	z-index: 10;
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: var(--swiper-theme-color);
}

.autoplay-progress svg {
	--progress: 0;
	position: absolute;
	left: 0;
	top: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	stroke-width: 4px;
	stroke: var(--swiper-theme-color);
	fill: none;
	stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
	stroke-dasharray: 125.6;
	transform: rotate(-90deg);
}

.swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: #000;
	opacity: 1;
	/* background: rgba(0, 0, 0, 0.2); */
}

.swiper-pagination-bullet-active {
	color: #fff !important;
	background: #038b00; /* #007aff;*/
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	width: 24px;
}
