/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
@import './roboto.css';
/* 
html {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05em;
	word-spacing: 0;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: relative;
	height: 100vh;
	font-size: 10px;
} */

body {
	height: 100vh;
	font-size: 1.5rem;
}

#root {
	height: 100%;
}

* {
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
}

input,
textarea {
	-webkit-user-select: auto !important;
	-khtml-user-select: auto !important;
	-moz-user-select: auto !important;
	-ms-user-select: auto !important;
	-o-user-select: auto !important;
	user-select: auto !important;
}

.firebase-emulator-warning {
	display: none;
}

:root {
	--ck-z-default: 100 !important;
	--ck-z-modal: calc(var(--ck-z-default) + 9999) !important;
}
