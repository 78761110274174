@font-face {
	font-family: 'hindiunicode';
	src: local('hindiunicode'), local('hindiunicode-Regular'), url(../fonts/surekhnormal-new.woff2) format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'hindiunicode';
	src: local('hindiunicode'), local('hindiunicode-Bold'), url(../fonts/surekhbold-new.woff2) format('woff2');
	font-weight: 500 700;
	font-style: normal;
}
